div.react-tutorial-js{
  position: fixed;
  top:0;
  left:0;
}

div.react-tutorial-js-wrap{
  position: fixed;
  box-sizing: content-box;
  z-index: 999997;
  background-color: #000;
  opacity: .4;
  background: -moz-radial-gradient(center,ellipse cover,rgba(0,0,0,0.4) 0,rgba(0,0,0,0.9) 100%);
  background: -webkit-gradient(radial,center center,0px,center center,100%,color-stop(0%,rgba(0,0,0,0.4)),color-stop(100%,rgba(0,0,0,0.9)));
  background: -webkit-radial-gradient(center,ellipse cover,rgba(0,0,0,0.4) 0,rgba(0,0,0,0.9) 100%);
  background: -o-radial-gradient(center,ellipse cover,rgba(0,0,0,0.4) 0,rgba(0,0,0,0.9) 100%);
  background: -ms-radial-gradient(center,ellipse cover,rgba(0,0,0,0.4) 0,rgba(0,0,0,0.9) 100%);
  background: radial-gradient(center,ellipse farthest-corner,rgba(0,0,0,0.4) 0,rgba(0,0,0,0.9) 100%);
  filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr='#66000000',endColorstr='#e6000000',GradientType=1)";
  -ms-filter: "alpha(opacity=50)";
  filter: alpha(opacity=50);
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
  top:0;
  left:0;
  width: 100vw;
  height: 100vh;
}

div.react-tutorial-js-box{
  box-sizing: content-box;
  position: absolute;
  z-index: 9999998;
  background-color: #e1f4fd;
  background-color: #e1f4fd;
  box-shadow: 0 2px 15px rgba(0,0,0,.4);
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
}


.react-tutorial-js-display{
  z-index: 999999;
}

.react-tutorial-js-tooltip {
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  position: absolute;
  font-size:  .8em;
  color: #fff;
  background-color: #000;
  width: 250px;
  padding: 10px;
  border-radius:3px;
  z-index: 999999;
  -webkit-transition: all .3s ease-out;
  -moz-transition: all .3s ease-out;
  -ms-transition: all .3s ease-out;
  -o-transition: all .3s ease-out;
  transition: all .3s ease-out;
  line-height: 1.4em;
}

.react-tutorial-js-tooltip:after{
    content:'';
    display:block;
    position:absolute;
    height:0;
    width:0;
    bottom:-13px;
    left:15px;
    border:13px transparent solid;
    border-right-width:0;
    border-left-color:#000;
    transform:rotate(90deg);            /* 傾きをつける */
    -webkit-transform:rotate(90deg);
    -o-transform:rotate(90deg);
    z-index: 999999;
}
.close{
  margin: 0 5px;
  cursor: pointer;
  font-size: 1.5em;
}
