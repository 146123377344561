div.main-content.calendar{

}

div.current-month{
  margin-left: 20px;
  font-size: .8em
}

div.calendar-monthly{
  display: flex;
  flex-direction: column;
  position: relative;
}
 div.week{
  display: flex;
  position: relative;
  flex-direction: row;
}

 div.week div.date{
  width: calc(100% / 7);
  margin-top: 2px;
  margin-right: 2px;
  height: 37px;
  width: 50px;
  padding: 10px;
  font-size: .8em;
  background-color: #fff;
  color:#727171;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

div.week div.date.past{
  background-color: #dcdddd;
  cursor:default;
}
 div.week div.date:first-child{

}
div.week div.date.sunday{
 color: #e60012;
}

div.week div.date.saturday{
  color: #00a0e9;
}
div.week div.date.selected{
  background-color: var(--color-selected);
}
 div.week.header{

   width: 100%;
   height: 35px;
 }
 div.week.header div.date{
   height: 30px;
   background-color: #b5b5b6;
   text-align: center;
   font-weight: bold;
   border: none;
 }

 div.date:nth-child(7n) {
}


div.date.start{
    font-weight: bold;
}

div.change-month{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  font-size: .9em;
}
div.change-month img{
  width: 9px;
  margin: 0 10px ;

}
div.change-month div.progress,
div.change-month div.back{
  width: 9px;
}
div.change-month div.back{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: auto;
  cursor: pointer;
  margin-left :6px;
}
div.change-month div.current{
  padding-top: 5px;
}
div.change-month div.progress{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
  margin-right: 6px;

}
