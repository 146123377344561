
main.GroupCreateApp{
  background-color: var(--color-background-main);
  padding: 50px 20px 50px 20px;
  color:var(--color-black);
}


section.create-form{
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 639px) {
  main.GroupCreateApp{
    padding:10px;
    box-sizing: border-box;
  }
  section.create-form{
    flex-direction: column;
  }
}

div.input-box{
  width: 100%;;
}

div.form,
div.calendar{

}
div.form{
  width: 445px;
}

@media screen and (max-width: 639px) {
  div.form{
    width: 100%;
  }
}



div.calendar{
  margin-left: 40px;
  margin-top: 30px;
}
div.currendar-box{
  position: relative;
}

section.btns{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}


@media screen and (max-width: 639px) {
  div.calendar{
    margin-left: 0;
  }
  section.btns{
    margin-top: 30px;
  }
}


button.create-btn{
  width: 500px;
  height: 40px;

  font-weight: bold;
}

section.created{
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

div.url-box{
  display: flex;
  flex-direction: row;
  margin: 20px auto;
}
div.url-box div.label{
  font-size:.8em;
  border-right: 1px solid #ccc;
  padding: 10px;
}
div.url-box div.url{
  padding: 10px;
  background-color: #fff;
  font-size: .8em;
  color:#231815;

}
section.created button.create-btn{
   width: 300px;
   padding: 10px 0;
}

div.copy{
  background-color: #898989;
  color:#fff;
  font-size: .8em;
  padding: 0 10px;
  display:flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
    width: 76px;
}

div.copy:active{
  background: var(--color-selected);
}

div.created-img{
  width: 258px;
}
div.created-img img{
  width: 100%;
}

div.image1{
  position: absolute;
  width: 198px;
  top:-65px;
  left:0;
}
div.image2{
  position: absolute;
  width: 280px;
  top:-65px;
  right:0;
}

header div.inner{
  display: flex;
  flex-direction: row;
  justify-content:space-around;
  width: 840px;
}
div.image_create_1{
  width: 140px;
}
div.image_create_2{
  width: 140px;
}

div.descriptions{
  margin: 0 auto 40px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 860px;
  box-sizing: border-box;

}
div.descriptions div.description-item{
  width: 265px;
  height: 188px;
  background-color: #fff;
  margin: 0 auto;
}
div.descriptions div.description-title{
  background-color: #7bc4c8;
  width: 100%;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
}
div.descriptions div.description-content{
  padding-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}
div.descriptions div.description-content img{

}
div.descriptions div.description-title img{
  height: 46px;
  width: auto;

}
div.item-content div.react-datepicker-wrapper{
  width: calc(100% - 20px);
}
div.item-content div.react-datepicker-wrapper input{
  width: 100%;
}

@media screen and (max-width: 639px) {
  header div.inner{
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  div.copy{
    width: 120px;
    font-size: 1.2em;
  }
  div.url-box{
    width: 100%;
    box-sizing: border-box;
    font-size: .8em;
  }

  div.slider{
    width: 90%;
    margin: 0 auto 50px auto;
  }
  div.slider img{
    width: 100%;
  }
}
