@media screen and (max-width: 639px) {

  html, body{
    /* overflow: hidden; */
  }

  div.personal header.personal{
    display: none;
  }
  section.personal.header{
    display: flex;
    flex-direction: row;
    padding: 5px 10px;
  }
  section.personal.comment{
    display: block;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: .8em;
    box-sizing: border-box;
  }
  section.personal.header div.logo{
    width: 100px;
    padding: 0;
    height: 35px;
    overflow: hidden;

  }
  section.personal.header div.logo img{
    width: 100%;
  }
  section.personal.header h1{
    background-color: #fff;
    padding:0 10px;
    width:calc(100% - 100px);
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-size: .8em;
  }
}

div.wrap.personal{
  padding-bottom: 0;
  max-height: 100vh;
  overflow: hidden;
}
main.personal{
  box-shadow: none;
  padding: 0;
  color:var(--color-black);
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction:column;
  justify-content: center;
  box-sizing: border-box;
}

section.personal{
  margin: 0 auto;
  max-width: 100%;
}

@media screen and (max-width: 639px) {
  main.personal{
    flex-direction: column;
    min-width:100%;
    padding: 0;
    box-sizing: border-box;
  }

  section.personal{
    width: 100%;
  }
}

section.content-header{
  margin: 0;
  width: 100%;
  position: relative;
}

section.content-header h1{
  font-size: 1.5em;
  margin-bottom: 15px;
  margin-top:0;
  background-color: #fff;
  padding: 7px;
  min-width: 485px;
  width: calc(100% - 350px);
}

section.content-header div.comment{
  margin-bottom: 20px;
  padding: 0 5px;
  font-size: .8em;
  max-width: 100vw;
}


@media screen and (max-width: 639px) {
  section.content-header h1{
    min-width:auto;
    width: 100%;
  }
}

 div.time{
  font-size:1em;
  width: 180px;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  box-sizing:border-box;
}
div.time-input{
  display: flex;
  flex-direction: row;
  position: relative;
  max-width: calc(100% - 180px);
  overflow: hidden;

  box-sizing: border-box;
  height: calc(var(--vh, 100vh) - 280px);
}
div#time_select{
  width: 100%;
}
 div.time div.select{
  width: 100%;
  box-sizing:border-box;
}

div.time div.duration{
  margin-bottom: 20px;
  font-size: 1em;
  font-weight: bold;
}
div.time div.date-item{
  width: 100%;
  background-color: var(--color-gray);
  color:var(--color-black);
  padding: 10px 0;
  text-align: center;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 7px;
  box-sizing: border-box;
  font-size: .9em;
}
div.time div.date-item:last-child{
  margin-bottom: 0;
}
div.time div.date-item.selected{
  background-color: #fff;
}
section.calendar-time{
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 30px;
}


@media screen and (max-width: 639px) {
  section.calendar-time{
    flex-direction: column;
    margin: 0;
  }
  div.time{
   width: 100%;
   margin-right: 0;
   display: flex;
   flex-direction: row;
   padding: 0 10px;
   height: 50px;
   align-items: center;
   margin-bottom: 10px;
   font-size: .7em;
  }
  div.time div.duration{
    margin-bottom: 0;
    width: 100%;
  }

  div.time-input{
    width: 100%;
    max-width: 100%;
    height: calc(var(--vh, 100vh) - 132px);
  }
}


div.tlbox,
div.time-line-item,
div.user-info{
  width: 115px;
}

div.time_line_google div.time-line-item{
  width: 80px;
}
 div.user-info.label{
  width: 80px;
}


div.tlbox.form input{
  padding: 3px;
}


 div.user-info{
  padding: 5px;
  flex: 0 0 115px;
}

div.time-line-box{
  width: 100%;
  position: relative;
}
div.tlbox.form div.time-line-box{
  margin-left: -1px;
  width: calc(100% + 1px);
  border-left: 2px solid var(--color-border-storng);
  border-right: 2px solid var(--color-border-storng);

  overflow: hidden;
  box-sizing: border-box;
}
div.user-info{
  height: 40px;
  position: relative;
  background-color: #dcedda;

  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid var(--color-border);
  -webkit-backface-visibility:hidden;
backface-visibility:hidden;
}

 div.user-info.editing{
  margin-left: -1px;
  width: 116px;
  border-left: 2px solid var(--color-border-storng);
  border-right: 2px solid var(--color-border-storng);
  border-top: 2px solid var(--color-border-storng);
  background-color: #fddf9b;
}

div.user-info div.name{
    font-size: .7em;
    color:var(--color-black);
}
div.user-info div.name i{
  font-size: 1.2em;
  cursor: pointer;
}
div.time-line{
  width: 100%;
}

div.time-line.label{
  width:80px;
}

div.time-line.label div.user-info{
  width: 36px;
}
div.tlbox.label{
  color:var(--color-black);
  background-color: #fff;

}
div.time-line-box{
  background-color: #fff;
  box-sizing: border-box;
}

div.time-line div.time-line-item{
  height: 18px;
  border-top:  1px solid var(--color-border);
  border-right: 1px solid var(--color-border);
  padding-top:5px;
  background-color: #fff;

}
div.time-line div.time-line-item.dotted-border{
  border-top:  1px dotted var(--color-gray);
}
div.time-line div.time-line-item:first-child{
  border-top: 0;
}
div.time-line div.time-line-item:last-child{
  border-bottom:  1px solid var(--color-border);
}


div.tlbox div.time-line div.time-line-item.selected{

    background-color:#d3edfb;
    color:#333;
    font-size: .7em;
    padding: 2px 5px 0 5px;
}

div.tlbox.form div.time-line div.time-line-item.selected{
    opacity: 1;
    background-color: #d6d8b6;
}

div.tlbox.form div.time-line div.time-line-item{
  background-color: #fff;
}



div.tlbox div.time-line div.time-line-item.full{
      background-color: #2186c4;
      color:#fff;
}

div.tlbox.form div.time-line div.time-line-item{
  background-color: #fbe9ba;
}
div.tlbox.form div.time-line div.time-line-item.full{
  background-color: #487c8e;
  color:#fff;
}

div.time-line div.time-line-item.google{
  background-color: #eee;
  font-size: .7em;
  padding: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-top:1px solid #555;
}
div.time-line div.time-line-item.google{
  background-color: var(--color-black);
  color:#fff;
}
div.time-line div.time-line-item span.googl-text{
  display: none;
}
div.time-line div.time-line-item span.googl-text{
  display: block;
}

div.time-line  div.time-line-item-label{
  height: 18px;
  font-size: .7em;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: relative;
  border-right: 1px solid var(--color-border);
  border-top: 1px solid var(--color-border);
  background-color: #fff;
}
div.time-line div.time-line-item-label.no-border,
div.time-line  div.time-line-item-label:first-child{
  border-top: none;
}


div.time-display{
  display: flex;
  flex-direction: row;
  align-items: center;
}
div.time-display div.delete{
margin-left: auto;
font-size: 1.22em;
cursor: pointer;
z-index: 10001;
}

div.image3{
  position: absolute;
  width: 280px;
  top:-60px;
  right:0;
}

div.color{
  position: absolute;
  top: 0;
  left: 0;
  /* ミックスブレンドモード 乗算 */
  mix-blend-mode: multiply;
  background: #f0fccc;
  width: 100%;
  height: 100%;
  opacity: .02
}


@media screen and (max-width: 639px) {
  div.time-line-box{
    width: 115px;
  }

  div.tlbox.form div.time-line-box{
    width: 116px;
  }

  div.tlbox.label {
    position:absolute;
    top:0;
    left:0;

  }

}


  div.handle{
    background-color: #d00;
    height: 864px;
    opacity:0;
  }
  div.react-draggable{
    position: absolute;
  }


div.google-btn{
  width: 100%;
  margin-bottom: 20px;



}
div.google-btn div.create-btn{
  background-color: var(--color-link);
  color:#fff;
  padding: 10px;
  font-size: .8em;
    line-height: 1.3em;
    text-align: center;
}

div.google-calendar-message{
  font-size: .8em;
  color:var(--color-link);
  font-weight: bold;
}


div.time-line-items{
  display: flex;

}
div.time-line-items{
  overflow: auto;
  overflow-x: hidden;
}
div.time-line-warp{
  flex-direction: column;
  padding-left: 80px;
  display: flex;
  height:100%;
  overflow: hidden;
}
div.time-line-warp.with-google{
    padding-left: 160px;
}

div.time-line-items{
  flex-direction: row;
}

div.time-line-info{
  min-height:40px;
  display: flex;
  flex-direction: row;
  overflow-x: hidden;
}
div.time-line-items{

}
div.time_line_label{
  overflow: hidden;
  margin-top: 40px;
  position: absolute;
  top:0;
  left: 0;
}
div.time_line_google{
  overflow: hidden;
  margin-top: 40px;
  position: absolute;
  top:0;
  left: 80px;
  width: 115px;
}
div.time-line-btn{
  min-height:30px;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}
div.timeline-update-btn,
div.timeline-update-btn-bottom,
div.timeline-update-btn-bottom-g{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-gray);
  width: 115px;
  height: 30px;
  color:#fff;
  font-size: .8em;
  flex: 0 0 115px;
}
div.timeline-update-btn.editing{
  background-color: var(--color-link);
  border-left: 2px solid var(--color-border-storng);
  border-right: 2px solid var(--color-border-storng);
  border-bottom: 2px solid var(--color-border-storng);
  width: 116px;
  margin-left: -1px;
  cursor: pointer;
}
div.timeline-update-btn-bottom,
div.timeline-update-btn-bottom-g{
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;

}
div.info-header,
div.info-header-google{
  top:0;
  left:0;
  width: 80px;
  position: absolute;
  height: 40px;
}
div.info-header-google{
  left: 80px;
  font-size: .7em;
  color:var(--color-link);
}
div.timeline-update-btn-bottom-g{
  left: 80px;
}

@media screen and (max-width: 639px) {

  div.time-line-items{
    height: auto;
    max-height: none;
  }
  div.timeline-update-btn-bottom,
  div.timeline-update-btn-bottom-g{

    width: 80px;
  }

  div.tlbox.label.time_line_google{
    top:0;
    left: 80px;
    width: 80px;
  }
}

div.move_btn{
  position: absolute;
  top:0;
  font-size: 2.8em;
  color: #333;
  opacity:.77;
  z-index: 1000;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
div.move_btn.to_right{
  right:20px;
}
div.move_btn.to_left{
  left:20px;
}
div.move_btn i{
  cursor: pointer;
}
@media screen and (max-width: 639px) {
  div.move_btn.to_right{
    right:10px;
  }
  div.move_btn.to_left{
    left:10px;
  }
  div.move_btn{
    font-size: 2.5em;
  }
}

div.add-person{
  font-size: .8em;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex:  0 0 80px;
}
